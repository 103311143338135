import React, { createContext } from 'react'

type LoginModalContextProps = {
  showLoginModal: (alertMessage?: React.ReactNode) => void
}

const LoginModalContext = createContext<LoginModalContextProps>({
  showLoginModal: (_alertMessage?: React.ReactNode) => {},
})

export default LoginModalContext
